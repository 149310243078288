@import "~@fontsource/vt323/index.css";
@import "~@fontsource/francois-one/index.css";
@import "~@fontsource/luckiest-guy/index.css";

// Colors
$grayLight	: #cac6c5;
$offWhite	: #e7e7e7;
$white: rgba(255,255,255,1);
$linkHoverColorBlue	: rgb(64,185,222);
$linkHoverColorGreen	: rgb(141, 255, 64);
$specialBlue	: rgb(100,224,215);
$specialGreen: rgba(162,255,177,1);
$specialPink	: rgb(252,3,144);
$specialOrange	: rgba(252,165,3,.8);
$clearSky	: rgba(64,185,222,.6);
$black: black;

/* Functions */
@function cos($angle) {
  $cos: 0;
  $angle: rad($angle);
  // Iterate a bunch of times.
  @for $i from 0 through 10 {
    $cos: $cos + pow(-1, $i) * pow($angle, 2 * $i) / fact(2 * $i);
  }
  @return $cos;
}

@function sin($angle) {
  $sin: 0;
  $angle: rad($angle);
  // Iterate a bunch of times.
  @for $i from 0 through 10 {
    $sin: $sin + pow(-1, $i) * pow($angle, (2 * $i + 1)) / fact(2 * $i + 1);
  }
  @return $sin;
}

@function pow($number, $exp) {
  $value: 1;
  @if $exp > 0 {
    @for $i from 1 through $exp {
      $value: $value * $number;
    }
  }
  @else if $exp < 0 {
    @for $i from 1 through -$exp {
      $value: $value / $number;
    }
  }
  @return $value;
}

@function rad($angle) {
  $unit: unit($angle);
  $unitless: $angle / ($angle * 0 + 1);
  // If the angle has 'deg' as unit, convert to radians.
  @if $unit == deg {
    $unitless: $unitless / 180 * pi();
  }
  @return $unitless;
}

@function pi() {
  @return 3.14159265359;
}

@function fact($number) {
  $value: 1;
  @if $number > 0 {
    @for $i from 1 through $number {
      $value: $value * $i;
    }
  }
  @return $value;
}


/* Mixins */
//basic outline for the text
@mixin outline($color: $white) {
	text-shadow: 1.5px 1.5px 0 $color,
    			 -1.5px -1.5px 0 $color,
    			 -1.5px -1.5px 0 $color,
    			 -1.5px 1.5px 0 $color,
    			 1.5px -1.5px 0 $color;
}

@mixin shade($type, $color: #3498db, $borderColor: $white, $depth: 20, $angle: 135deg, $long: false, $fade: false) {
    $angle: ($angle - 90);
    $x: 1.1 * cos($angle) + 0px;
    $y: 1.1 * sin($angle) + 0px;
    $darken: (lightness($color)/$depth)/2;
    $opacify: 0;
    $shadow: ();

    @if $long == true{
      $darken:0;
    }

    @if $fade == true {
      $opacify: (opacity($color)/$depth) ;
        // added this for rendering in some browsers, remove if you like.
      @include translateZ(0);
    }

    @for $i from 1 through $depth {
      $shadow: $shadow, $i*$x $i*$y 0 hsla(hue($color) , saturation($color), (lightness($color) - ($i * $darken)), 1 - ($i * $opacify));
    }
    #{$type}-shadow:  1.5px 1.5px 0 $borderColor,
                      0px 1.5px 0 $borderColor,
                      -1.5px -1.5px 0 $borderColor,
                      -1.5px -1.5px 0 $borderColor,
                      -1.5px 1.5px 0 $borderColor,
                       1.5px -1.5px 0 $borderColor,
                       $shadow;
}

/* Static */

  /* Header */
  .header {
    z-index: 6;
    position: fixed;
    width: 100vw;
    height: 125px;
    padding: 2vw;
    background: linear-gradient($white 90%, rgba(255,255,255,0));
  }

  @media only screen and (max-width: 600px) {
    .header {
      height: 145px;
    }
  }

  @media only screen and (min-width: 1600px) {
    .header {
      height: 145px;
    }
  }

  .icon {
    width: 50px;
    height: 75px;
    float: left;
    height: auto;
  }

  @media only screen and (max-width: 800px) {
    .icon {
      width: 10vw;
    }
  }

  .RecipeSearchField {
    margin-left: auto;
    margin-right: auto;
    padding-top: 3vw;
    width: 90vw;
  }

  @media only screen and (max-width: 600px) {
    .RecipeSearchField {
      height: 15vw;
      padding-top: 6vw;
    }
  }

  .RecipeSearchField img {
    vertical-align: bottom;
    padding-right: 10px;
    margin-left: 30vw;
  }

  .RecipeSearchField input {
    width: 15vw;
    font-size: 1.2em;
    border: none;
    border-bottom: #757575 solid 2px;
    outline: none;
    font-weight: bold;
  }

  @media only screen and (max-width: 600px) {
    .RecipeSearchField input {
      width: 32vw;
      font-size: 1em;
    }

    .RecipeSearchField img {
      height: 25px;
      margin-left: 9vw;
    }

    .RecipeSearchField input:focus {
      width: 40vw !important;
      transition: 0.5s;
      border-bottom: #757575 solid 2px;
      outline: none;
    }

    .search-trail {
      padding-top: 6vw !important;
    }
  }

  .RecipeSearchField input:focus {
    width: 20vw;
    transition: 0.5s;
    border: none;
    border-bottom: #757575 solid 2px;
    outline: none;
  }

  .search-trail {
    position:relative;
    left: -5vw;
    z-index: 10;
    padding-top: 3vw;
    padding-bottom: 50px;
    font-size: 10px;
    margin-left: auto;
    margin-right: auto;
    width: fit-content;
    white-space:nowrap
  }

  .search-trail a {
    text-decoration: none;
    text-align: center;
    background: $white;
    border-style: solid;
    border-width: 1px;
    box-shadow: 0 0 4px gray;
    border-radius: 8px;
    padding: 5px;
    margin: 5px;
    cursor: pointer;
    color: black;
  }

  .search-trail .parent {
    background-color: $specialPink;
  }

  .search-trail .child {
    background-color: $linkHoverColorBlue;
  }

  .search-trail .current {
    box-shadow: 0 0 6px $specialPink;
  }

  .search-trail .current:hover {
    box-shadow: 0 0 6px $specialPink;
  }

  .search-trail a:hover {
    box-shadow: 0 0 6px $clearSky;
  }

  .info_button {
    width: 3vw;
    height: 3vw;
    cursor: pointer;
    padding-left: 4vw;
  }

  .search_description {
    width: 75vw;
    margin: auto;
    background-color: white;
    padding: 15px;
    border-radius: 8px;
    border-style: solid;
  }

  @media only screen and (max-width: 600px) {
    .search_description {
      margin-top: 8vw;
      margin-bottom: -8vw;
    }
  }

  /* Hamburger menu */

  #Nav-Icon {
    width: 5vw;
    height: 5vw;
    float: right;
    margin-top: 2vw;
    margin-right: 4vw;
    cursor: pointer;

    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .5s ease-in-out;
    -moz-transition: .5s ease-in-out;
    -o-transition: .5s ease-in-out;
    transition: .5s ease-in-out;
  }

  @media only screen and (max-width: 600px) {
    #Nav-Icon {
      margin: 5vw;
      margin-right: 13vw !important;
    }
  }

  @media only screen and (max-width: 800px) {
    #Nav-Icon {
      margin: 5vw;
      margin-right: 8vw;
    }
  }

  #Nav-Icon span {
    display: block;
    position: absolute;
    height: 4px;
    width: 50px;
    background: #000000;
    border-radius: 2px;
    opacity: 1;
    left: 0;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .25s ease-in-out;
    -moz-transition: .25s ease-in-out;
    -o-transition: .25s ease-in-out;
    transition: .25s ease-in-out;
  }

  #Nav-Icon span:nth-child(1) {
    top: 0px;
  }

  #Nav-Icon span:nth-child(2),#Nav-Icon span:nth-child(3) {
    top: 15px;
  }

  #Nav-Icon span:nth-child(4) {
    top: 30px;
  }

  #Nav-Icon.open span:nth-child(1) {
    top: 18px;
    width: 0%;
    left: 50%;
  }

  #Nav-Icon.open span:nth-child(2) {
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
  }

  #Nav-Icon.open span:nth-child(3) {
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }

  #Nav-Icon.open span:nth-child(4) {
    top: 18px;
    width: 0%;
    left: 50%;
  }

  #menu {
    position: fixed;
    top: 8vw;
    right: 0px;
    height: 100%;
    border-style: solid;
    border-radius: 20px;
    border-width: thin;
    background-color: rgba(159, 242, 250, 1);
    box-shadow: -2px 0px 2px $specialBlue;
    font-family: "Luckiest Guy";
    color: $specialBlue;
    width: 20%;
    z-index: 10;
    transform: translateX(100%);
    transition: transform 300ms;
  }

  @media only screen and (max-width: 600px) {
    #menu {
      width: 65%;
      top: 13vh;
    }
    #menu.open {
      transform: translateX(5%);
    }
  }

  #menu ul {
    list-style: none;
    font-size: 30px;
  }

  #menu li {
    padding-top: 35px;
    padding-left: 0px;
  }

  #menu .user-label {
    font-size: 16px;
    color: black;
  }

  #menu a {
    text-decoration: underline;
    color: black;
  }

  #menu a:hover {
    color: gray;
  }

  #menu.open {
    transform: translateX(10%);
  }


.create-button {
  position: fixed;
  bottom: 50px;
  right: 80px;
  height: 50px;
  width: 50px;
  border-radius: 5px;
  border-style: solid;
  border-color: black;
  background-color: $clearSky;
  font-size: 48px;
  line-height: 45px;
}

.create-button a {
  color: black;
  text-decoration: none;
  position: fixed;
  bottom: 58px;
  right: 93px;
}

.content {
  padding-top: 200px;
  min-height: 100vh;
  min-width: 100vw;
  // background: linear-gradient($white 23%, $specialGreen 64%);
  background-attachment: fixed;
  overflow: hidden;
}

@media only screen and (max-width: 600px) {
  .content {
    padding-top: 180px;
    max-width: 100vw;
    min-width: 90vw;
    overflow-x: hidden;
  }

  .create-button {
    position: fixed;
    bottom: 30px;
    right: 30px;
  }

  .create-button a {
    position: fixed;
    bottom: 38px;
    right: 43px;
  }
}

/* Recipe list */

.RecipeList {
  width: 100%;
  margin: auto;
}

.RecipeList a {
  text-decoration: none;
  color: black;
}

@media only screen and (max-width: 600px) {
  .Recipe {
    margin: 20px !important;
    padding: 25px !important;
  }

  .Recipe ul {
    padding: 10px !important;
    width: 300px;
  }
}

.Ingredients h2 {
  font-size: 1em;
  font-weight: normal;
}

.Ingredients #checkboxes label {
  float: left;
}
.Ingredients #checkboxes ul {
  margin: 0;
  list-style: none;
  float: left;
}

.Directions h2 {
  font-size: 1em;
  font-weight: normal;
  padding-top: 10px;
}

@media only screen and (min-width: 600px) {
  .Directions {
    float: right !important;
  }
}

.Recipe {
  background-color: $white;
  border-radius: 20px;
  border-style: solid;
  border-width: thin;
  border-color: rgb(168,168,168);
  padding: 40px;
  margin: 50px;
  list-style-type: none;
  overflow: auto;
  box-shadow: 2px 2px #888888;
}

.Title {
  margin-bottom: 15px;
  position: relative;
}

@media only screen and (max-width: 600px) {
  .recipe-title-text {
    max-width: 60vw;
  }

  .Title .edit_button {
    right: 10px !important;
  }
}

.Title .edit_button {
  position: absolute;
  margin: 0px;
  top: -20px;
  right: 50px;
  min-width: 30px;
  min-height: 30px;
  max-width: 70px;
  max-height: 70px;
  cursor: pointer;
}

@media only screen and (max-width: 600px) {
  .Title .share_button {
    min-width: 5px;
    min-height: 5px;
    max-width: 30px;
    max-height: 30px;
    right: -50px;
  }
}

.Title .share_button {
  position: absolute;
  top: -5px;
  right: -20px;
  max-width: 40px;
  cursor: pointer;
}

.Ingredients {
  width: 25%;
}

.Ingredients ul{
  list-style-type: none;
  display: inline;
  float:left;
}

.Ingredients li {
  margin-bottom: 10px;
}

.Directions {
  width: 70%;
  padding-right: 40px;
}

.Directions ul {
  list-style-type: none;
}

.Directions li {
  margin-bottom: 10px;
}

/* Privacy policy */

.Privacy {
  width: 100%;
  margin: auto;
  background-color: $white;
  border: solid;
  border-width: thin;
  border-radius: 20px;
  margin-top: 50px;
  padding: 4vh;
  left: 12%;
  width: 75%;
  margin-bottom: 75px;
}

.Privacy h2 {
  font-size: 36px;
}

.Privacy strong {
  font-size: 24px;
}

.Privacy p {
  font-size: 12px;
}

/* Roadmap */

.RoadMap {
  width: 100%;
  margin: auto;
  background-color: $white;
  border: solid;
  border-width: thin;
  border-radius: 20px;
  margin-top: 50px;
  padding: 4vh;
  width: 75%;
  font-size: 36px;
  margin-bottom: 75px;
}

/* About */

.Static-Info {
  width: 100%;
  margin: auto;
  background-color: $white;
  border: solid;
  border-width: thin;
  border-radius: 20px;
  margin-top: 50px;
  padding: 4vh;
  left: 12%;
  width: 75%;
  margin-bottom: 75px;
}

/* Authorization */
@media only screen and (min-width: 750px) {
  .auth {
    left: 31% !important;
    width: 400px !important;
  }
}

.auth {
  position: absolute;
  left: 10%;
  top: 20%;
  width: 240px;
  height: 400px;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.8);
  border-width: medium;
  border-radius: 12px;
  background-color: $white;
  padding: 25px;
  box-shadow: 0 0 8px;
  overflow: hidden;
}

.auth-container {
  width: 100vw;
  height: 100%;
  position: absolute;
  left: 0px;
  top: 0px;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 6;
  overflow: hidden;
}

.auth h1 {
  text-align: center;
  font-size: 30px;
  font-family: 'Francois One';
}

.auth form {
 margin-bottom: 15px;
}

.auth input {
  width: 100%;
  padding: 12px 20px;
  margin: 10px 0;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  font-size: 18px;
}

.auth a {
  color: #8fd3f4;
}

.popover_dismiss {
  text-decoration: underline;
  float: right;
  cursor: pointer;
}

.auth button {
  width: 100%;
  background-image: linear-gradient(to right, #84fab0 0%, #8fd3f4 51%);
  text-transform: uppercase;
  color: $white;
  padding: 14px 20px;
  margin: 12px 0;
  border: none;
  border-radius: 2px;
  cursor: pointer;
}

.auth button:hover {
  background-image: linear-gradient(to right, #84fab0 12%, #8fd3f4 84%);
}

.auth_options_header{
  text-align: left;
}

.auth_options {
  cursor: pointer;
  text-decoration: underline;
  font-size: 13px;
  padding: 8px;
}

/* Banners */

.not-logged-in-banner {
  display: flex;
  width: 100%;
}

.not-logged-in-cta {
  flex: 50%;
  padding-left: 2%;
  max-width: 50%;
  margin: 0;
}

.not-logged-in-banner a {
  padding: 10px;
  text-decoration: none;
}

@media only screen and (min-width: 600px) {
  .not-logged-in-banner {
    margin-top: 0px;
  }

  .not-logged-in-banner a {
    padding: 40px;
  }

  .login-text {
    display: inline;
    max-width: 50%;
    font-size: 86px !important;
  }
}

.login-text {
  display: inline;
  max-width: 50%;

  z-index: 0;
  margin-top: 10px;
  padding-left: 20px;
  padding-right: 20px;
  font-family: 'Francois One';
  font-size: 56px;
  color: $specialBlue;
  text-decoration: none;

  @include outline($grayLight);

  transition: all 400ms ease-in-out;
}

.login-text--pushDown {
	@include shade(text, #aaa, #333, 10, 135deg, true);

	&:hover {
		transform: translate(2px, 2px);
		@include outline($grayLight);
	}
}

.login-text--shadow {
	&:hover {
		transform: translate(5px, 0);
	}
}

.no-recipes-banner {
  background-color: #ff4e00;
  background-image: linear-gradient(315deg, #ff4e00 0%, #ec9f05 34%);
  border-radius: 20px;
  border-style: solid;
  border-width: thin;
  border-color: rgb(168,168,168);
  padding: 40px;
  margin: 50px;
  overflow: auto;
  box-shadow: 2px 2px #888888;
  font-size: 36px;
  text-align: center;
}

.warning-container {
  position: fixed;
  height: 70px;
  width: 300px;
  top: 1;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  margin-bottom: 50px;
  background-color: #ff4e00;
  background-image: linear-gradient(315deg, #ff4e00 0%, #ec9f05 34%);
  border-radius: 20px;
  border-style: solid;
  border-width: thin;
  border-color: rgb(168,168,168);
  padding: 20px;
  box-shadow: 2px 2px #888888;
  font-size: 16px;
  overflow: auto;
  text-align: center;
  z-index: 7;
}

.loader-container {
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.9);
  z-index: 9;
}

.svg-loader{
  position: absolute;
  height: 40vmin;
  padding: 3vmin 20vmin;
  top: 300px;
  left: 70vmin;
}

/* Builder */

.builder {
  padding-left: 40px;
  padding-top: 35px;
  margin: 50px;
  width: 1000px;
  margin: auto;
}

@media only screen and (max-width: 600px) {

  .builder {
    margin: 0px;
    width: 100%;
  }

  .builder .title {
    width: 70%;
  }

  .builder .ingredients {
    width: 70% !important;
  }

  .builder .directions {
    width: 70% !important;
    margin-left: 0px !important;
  }

  .builder form button {
    float: left;
  }
}

.builder form input[type=text], textarea {
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 100%;
  padding: 12px;
  margin: 8px 0;
  border: 1px solid #ccc;
  border-radius: 4px;
  resize: vertical;
  font-size: 18px;
}

.builder .recipe {
  padding-top: 70px;
  width:100%;
}

.builder .ingredients {
  width: 25%;
  display: inline-block;
}

.builder .directions {
  width: 65%;
  margin-left: 90px;
  display: inline-block;
}

.builder form textarea{
  height: 300px;
}

div.tags {
  overflow: auto;
  white-space: nowrap;
}

.tags input {
  max-width: 20vw;
}

.tag {
  text-align: center;
  background: $white;
  border-style: solid;
  border-width: 1px;
  box-shadow: 0 0 4px gray;
  border-radius: 4px;
  padding: 8px;
  margin: 5px;
  margin-top: 12px;
  margin-bottom: 12px;
  cursor: pointer;
  background-color: $specialOrange;
  color: black;
}

.mint_button button {
  text-align: center;
  background: $white;
  border-style: solid;
  border-width: 1px;
  box-shadow: 0 0 4px gray;
  min-width: 100px;
  border-radius: 4px;
  padding: 4px;
  margin-top: 15px;
  cursor: pointer;
  background-color: $linkHoverColorGreen;
  color: black;
}

button[type=submit] {
  background-color: #4CAF50;
  color: $white;
  padding: 12px 20px;
  margin: 12px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  float: right;
}

button[type=submit]:hover {
  background-color: #45a049;
}

button[type=delete] {
  background-color: #ec3a61;
  color: $white;
  padding: 12px 20px;
  margin: 12px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  float: right;
}

button[type=delete]:hover {
  background-color: #dc0b2e;
}

button[type=clear] {
  background-color: #28A4F3;
  color: $white;
  padding: 12px 20px;
  margin: 12px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  float: right;
}

button[type=clear]:hover {
  background-color: #73C5F8;
}

button[type=import] {
  background-color: #28A4F3;
  color: $white;
  padding: 12px 20px;
  margin: 12px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

button[type=import]:hover {
  background-color: #28A4F3;
}

.download-icons {
  display: inline;
  margin-top: 25px;
  padding-bottom: 100px;
  text-align: center;
}

.download-icons h2 {
  padding-bottom: 20px;
  text-align: center;
  font-family: 'Luckiest Guy';
}

.download-icons a {
  padding: 50px;
}

.Suggestions-Dismiss {
  position: relative;
  top: 5px;
  right: -170px;
  width: 10px;
  font-size: 20;
  font-family: 'Luckiest Guy';
  color: $black;
  background-color: $specialGreen;
  border-radius: 4px;
  margin-right: 20px;
  padding: 6px;
  border-style: solid;
  border-width: thin;
  cursor: pointer;
}

.Suggestions-Dismiss p{
    margin: 0px;
    padding-top: 4px;
    padding-left: 1px;
}

.Suggestions-Dismiss:hover {
  background-color: $specialBlue;
}

.Suggestions {
  max-width: 200px;
  border: 1px solid #eeeeee;
  border-radius: 4px;
  background-color: $white;
  margin-left: 15px;
  margin-top: 12px;
  box-shadow: 0px 1px 2px 1px rgba(0, 0, 0, 0.4);
}

.Suggestions ul {
  list-style-type: none;
}

.Suggestions li {
  font-weight: normal;
  color: #333333;
  margin: 0 0 3px 0;
}

.Suggestions .active-suggestion,
.Suggestions li:hover {
  background: #efefef;
  cursor: pointer;
  font-weight: 700;
}

.verificationView {
  margin-top: 150px;
  font-family: "Francois One";
  font-size: 30px;
  text-align: center;
}

// recipe listings overall
.RecipeListContainer {
  padding-top: 4vw;
}

.RecipeListContainer h1 {
  padding-left: 4vw;
  font-family: 'Francois One';
  color: $specialBlue;
  @include outline($grayLight);
  @include shade(text, #aaa, #333, 6, 135deg, true);
  font-size: 36px;
}

.recipe-request-container {
  padding-top: 4vw;
}

// Exernal recipe listings
.externalRecipeView {
  font-family: "Francois One";
  width: 100%;
  color: black;
}

.externalRecipeView li {
  list-style-type: none;
  display: inline-grid;
}

.externalRecipeView a {
  text-decoration: none;
}

.externalRecipeView a:visited {
  color: black;
}

.externalRecipeListing {
  text-align: center;
  background: $white;
  border-style: solid;
  border-width: 1px;
  box-shadow: 0 0 4px gray;
  min-width: 100px;
  border-radius: 4px;
  padding: 10px;
  margin: 10px;
  cursor: pointer;
  background-color: $specialOrange;
  color: black;
}

.externalRecipeView a:hover {
  box-shadow: 0 0 6px $linkHoverColorGreen;
}

// Internal recipe listings
@media only screen and (max-width: 600px) {
  .recipeView ul {
    padding: 0px;
  }

  .recipeView {
    font-family: "Francois One";
    width: 100%;
    color: black;
  }

  .externalRecipeView {
    padding-top: 10vw;
  }
}

.recipeView {
  font-family: "Francois One";
  width: 100%;
  color: black;
}

.recipeView li {
  list-style-type: none;
  display: inline-grid;
}

.recipeView a {
  text-decoration: none;
}

.recipeView a:visited {
  color: black;
}

.recipeListing {
  text-align: center;
  background: $white;
  border-style: solid;
  border-width: 1px;
  box-shadow: 0 0 4px gray;
  min-width: 100px;
  border-radius: 4px;
  padding: 10px;
  margin: 10px;
  cursor: pointer;
  color: black;
}

.watchedRecipeListing {
  text-align: center;
  background: $specialBlue;
  border-style: solid;
  border-width: 1px;
  box-shadow: 0 0 4px gray;
  min-width: 100px;
  border-radius: 4px;
  padding: 10px;
  margin: 10px;
  color: black;
  cursor: pointer;
}

.recipeView a:hover {
  box-shadow: 0 0 2px $linkHoverColorBlue;
}

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  border-radius: 34px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  border-radius: 50%;
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: #2196F3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

.db_indicator {
  position: relative;
  display: inline-block;
  left: 20px;
  top: 7px;
  font-size: 18px;
  font-weight: bold;
}

.reset_password_view {
  margin-top: 100px;
  height: 200px;
  width: 500px;
}

.reset_password_form {
  margin-top: 100px;
  height: 200px;
}

.reset_password_form h1 {
  text-align: left;
  font-size: 15px;
  margin-left: 40px;
  font-family: 'Francois One';
}

.reset_password_form h2 {
  text-align: left;
  font-size: 25px;
  margin-left: 40px;
  font-family: 'Francois One';
}

.new_password input{
  margin-top: 25px;
  margin-left: 30px;
  width: 300px;
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  font-size: 18px;
}

.reset_password_view button {
  margin-left: 30px;
  margin-top: 25px;
  float: left;
  width: 300px;
  background-image: linear-gradient(to right, #84fab0 0%, #8fd3f4 51%);
  text-transform: uppercase;
  color: $white;
  padding: 14px 20px;
  border: none;
  border-radius: 2px;
  cursor: pointer;
}

.reset_password_view button:hover {
  background-image: linear-gradient(to right, #84fab0 12%, #8fd3f4 84%);
}

.tester {
  margin-left: 300px;
  margin-top: 25px;
  float: left;
  width: 300px;
  background-image: linear-gradient(to right, #84fab0 0%, #8fd3f4 51%);
  text-transform: uppercase;
  color: $white;
  padding: 14px 20px;
  border: none;
  border-radius: 2px;
  cursor: pointer;
}

.svg_preview {
  width:840px;
  height:600px;
  display: block;
  margin: auto;
  border-radius: 10px;
}

.svg_preview img {
  display: block;
  margin: auto;
}

.picker {
  position: relative;
  bottom: 350px;
  left: -20px;
}

.background_color_button {
  background-color: #65C4FF;
  color: $white;
  padding: 12px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  position: relative;
  bottom: 0px;
  left: 0px;
}

.minter_button {
  background-color: #EC1ADF;
  color: $white;
  padding: 12px 20px;
  margin-left: 25px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.view_collection_button {
  background-color: #006666;
  color: $white;
  padding: 12px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-left: 30px;
}

.mining_in_progress {
  position: absolute;
  top: 0px;
  width: 100%;
  min-height: 100vh;
  z-index: 8;
  background: rgba(255, 255, 255, 0.9);
}

.mining_in_progress iframe {
  position: absolute;
  padding: 3vmin 20vmin;
  top: 100px;
  left: 30vmin;
}

.landing-headline {
  padding-left: 0px;
  padding-right: 20px;
  font-size: 30px;
  margin-bottom: 0px;
  padding-bottom: 8px;
  padding-left: 22px;
  font-family: 'Francois One', sans-serif;
  text-align: center;
  color: rgba(0,0,0,.85);
  font-size: 36px;
  line-height: 75px;
  display: inline-block;
}

.landing-headline h2 {
  font-size: 25px;
}

.landing-sub {
  color: rgba(0,0,0,.75);
  font-size: 36px;
  line-height: 36px;
  padding-bottom: 25px;
  margin: auto;
  max-width: 600px;
}

@keyframes flickerAnimation {
  0%   { opacity:1; }
  50%  { opacity:0; }
  100% { opacity:1; }
}

@-o-keyframes flickerAnimation{
  0%   { opacity:1; }
  50%  { opacity:0; }
  100% { opacity:1; }
}

@-moz-keyframes flickerAnimation{
  0%   { opacity:1; }
  50%  { opacity:0; }
  100% { opacity:1; }
}

@-webkit-keyframes flickerAnimation{
  0%   { opacity:1; }
  50%  { opacity:0; }
  100% { opacity:1; }
}

.search-cta {
  color: red;
  display: inline;
  padding-right: 25px;
  font-family: "VT323";
  font-size: 26px;
  -webkit-animation: flickerAnimation 2s infinite;
  -moz-animation: flickerAnimation 2s infinite;
  -o-animation: flickerAnimation 2s infinite;
   animation: flickerAnimation 2s infinite;
}

.landing-screenshot img {
  width: 100%;
  padding-left: 5%;
}

.extension-screenshot img {
  width: 30%;
}

.extension-view {
  padding-top: 20px;
  padding-left: 5%
}

.extension-view h1 {
  font-family: 'Francois One';
  font-size: 36px;
}

.extension-view h2 {
  font-family: 'Francois One';
  color: $specialPink;
}

.newsletter-signup {
  display: inline-block;
}

.newsletter-signup input {
  border: 2px solid;
  border-radius: 4px;
  padding: 8px;
  margin-top: 13px;
}

.download-icons h2 {
  padding-top: 30px;
}

@media only screen and (max-width: 600px) {
  .not-logged-in-banner {
    display: inline;
  }

  .landing-screenshot img {
    display: inline;
  }

  .not-logged-in-cta {
    max-width: 70%;
    display: inline;
    margin: 0;
  }

  .landing-headline {
    font-size: 24px;
    line-height: 65px;
  }

  .landing-headline h3{
    font-size: 30px;
  }

  .search-cta {
    font-size: 8px;
    padding-right: 5px;
  }

  .landing-sub {
    padding-bottom: 20px;
  }

  .download-icons a {
    height: 40px;
  }
}

.recipe-request-container {
  margin: auto;
  padding: 20px;
  margin-top: 35px;
  width: 400px;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.8);
  border-width: thin;
  box-shadow: 0 0 8px;
  border-radius: 10px;
  background-color: white;
  text-align: center;
  font-size: 15px;
  font-family: 'Francois One';
}

.recipe-request-container input {
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  font-size: 18px;
}

.recipe-request-container label {
  font-size: 12px
}

.recipe-request-container input {
  width: 100%;
  padding: 12px 20px;
  margin: 10px 0;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

.recipe-request-container button {
  width: 100%;
  background-image: linear-gradient(to right, #84fab0 0%, #8fd3f4 51%);
  text-transform: uppercase;
  color: $white;
  padding: 14px 20px;
  margin: 12px 0;
  border: none;
  border-radius: 2px;
  cursor: pointer;
}

.recipe-request-container button:hover {
  background-image: linear-gradient(to right, #84fab0 12%, #8fd3f4 84%);
}

.finally {
  color: red;
  font-size: 10px;
}

.title-wrapper {
  display: inline;
}

.loader {
  position: absolute;
  left: 40px;
  bottom: 40px;
  color: red;
  font-family: "VT323";
  font-size: 45px;
  -webkit-animation: flickerAnimation 2s infinite;
  -moz-animation: flickerAnimation 2s infinite;
  -o-animation: flickerAnimation 2s infinite;
   animation: flickerAnimation 2s infinite;
}

.admin_options {
  position: fixed;
  width: 100%;
  text-align: center;
  top: 120px;
  font-size: 20px;
  z-index: 10;
}

.admin_options a {
  padding: 20px;
}

.user_view {
  padding-top: 40px;
  padding-left: 25px;
}

.user_view table, th, td {
  margin-top: 15px;
  padding: 5px;
  border: 1px solid black;
  border-collapse: collapse;
  background-color: white;
}

.countdown {
  margin-top: 200px;
  padding: 20px;
  margin: auto;
  width: 60%;
  text-align: center;
  font-size: 25px;
}

@media only screen and (max-width: 600px) {
  .countdown {
    width: 90%;
  }
}

.countdown .page-title {
  font-size: 70px;
  color: $specialBlue;
  padding-bottom: 40px;
  @include outline($grayLight);
  @include shade(text, #aaa, #333, 10, 135deg, true);
}

.countdown .timer {
  font-size: 30px;
  padding-bottom: 30px;
}

.countdown .counter {
  font-size: 45px;
  padding-bottom: 30px;
  color: $specialOrange;
}

.countdown form input[type=text] {
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 80%;
  padding: 12px;
  margin: 8px 0;
  border: 1px solid #ccc;
  resize: vertical;
  font-size: 18px;
}

.leprechaun {
  animation: MoveUpDown 5s linear infinite;
  position: fixed;
  left: 70%;
  bottom: -10;
  width: 10%;
}

@keyframes MoveUpDown {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-50px);
  }
}

.saveIndicatorRed {
  width: 30px;
  height: 30px;
  float: left;
  border-radius: 50%;
  background: radial-gradient(circle at 100px 100px, #F22713, #A51C0F);
  border:1px solid #C54D42;
  -moz-border-radius:50%;
  -webkit-border-radius:50%;
  margin-top: 13px;
  box-shadow: 1px 2px 5px #aaa;
}

.saveIndicatorGreen {
  width: 30px;
  height: 30px;
  float: left;
  border-radius: 50%;
  background: radial-gradient(circle at 100px 100px, #5BFD06, #2F780A);
  border: 1px solid #4BB116;
  -moz-border-radius:50%;
  -webkit-border-radius:50%;
  margin-top: 13px;
  box-shadow: 1px 2px 5px #aaa;
}

.builder-controls {
  position: fixed;
  top: 8vw;
  z-index: 10;
}

@media only screen and (max-width: 600px) {
  .builder-controls {
    position: fixed;
    top: 15vw;
    z-index: 10;
  }

  .builder-controls button {
    font-size: .4em;
  }
}

.blog {
  padding: 2vw;
  margin: 4vw;
  background-color: white;
  border-radius: 6px;
}

.FeatureBarContainer {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 150px;
  display: flex;
  justify-content: center;
}

.featureButton  {
  font-size: 30px;
  padding-left: 10px;
  cursor: grab;
}

#carousel {
  position: fixed;
  border: 2px solid;
  bottom: 0;
  background-color: $specialPink;
  border-radius: 6px;
  border-bottom: none;
  height: 175px;
  margin: auto;
  width: 60%;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}

.featuredRecipeView {
  padding: 20px;
  padding-top: 0px;
  list-style: none;
  width: 90%;
}

.featuredRecipeList {
  display: flex;
  overflow-x: auto;
  scroll-snap-type: x mandatory;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
}

.featuredRecipeList a {
  text-decoration: none;
  color: black;
}

.featuredRecipeListing {
  text-align: center;
  background: white;
  border-style: solid;
  border-width: 1px;
  box-shadow: 0 0 4px gray;
  min-width: 100px;
  border-radius: 4px;
  padding: 10px;
  margin: 10px;
  height: 80px;
  cursor: pointer;
  overflow: hidden;
  font-size: 12px;
  font-weight: bold;
  font-family: 'Francois One';
}

.featuredRecipeIngredients {
  text-align: left;
  list-style: none;
  padding-left: 0px;
  font-weight: normal;
  font-size: 8px;
}

.featuredLabel {
  font-size: 14px;
  padding-left: 15px;
  font-family: 'Francois One';
  vertical-align: middle;
}

#carousel.carouselClosed {
  transform: translateY(75%);
}

.ingredientView h1 {
  padding-left: 4vw;
  font-family: 'Francois One';
  color: $specialBlue;
  @include outline($grayLight);
  @include shade(text, #aaa, #333, 6, 135deg, true);
  font-size: 36px;
}

.ingredientRecipeList {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  scroll-snap-type: x mandatory;
  width: 75vw;
  margin: auto;
  scroll-behavior: smooth;
}

.ingredientRecipeListRecipe a {
  text-decoration: none;
  color: black;
  cursor: pointer;
}

.highlightedIngredient {
  color: $specialPink;
}

.ingredient_description {
  width: 75vw;
  margin: auto;
  padding: 15px;
}

.recipeListContainer{
  width: 75vw;
  margin: auto;
}

.ingredientRecipeListing {
  text-align: center;
  background: white;
  border-style: solid;
  border-width: 1px;
  box-shadow: 0 0 4px gray;
  min-width: 100px;
  border-radius: 4px;
  padding: 10px;
  margin: 10px;
  height: 100px;
  overflow: hidden;
  font-size: 12px;
  font-weight: bold;
  font-family: 'Francois One';
}

.ingredientRecipeListing:hover {
  box-shadow: 0 0 4px $linkHoverColorGreen;
}

.ingredientRecipeIngredients {
  text-align: left;
  list-style: none;
  padding-left: 0px;
  font-weight: normal;
  font-size: 8px;
}

.ingredientRecipeLabel {
  font-size: 14px;
  padding-left: 15px;
  font-family: 'Francois One';
  vertical-align: middle;
}

.relatedIngredients {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  scroll-snap-type: x mandatory;
  width: 75vw;
  margin: auto;
  scroll-behavior: smooth;
  font-size: 10px;
}

.relatedIngredients a {
  text-decoration: none;
  text-align: center;
  background: $white;
  background-color: $linkHoverColorBlue;
  box-shadow: 0 0 6px $specialPink;
  border-style: solid;
  border-width: 1px;
  box-shadow: 0 0 4px gray;
  border-radius: 8px;
  padding: 5px;
  margin: 5px;
  cursor: pointer;
  color: black;
}
